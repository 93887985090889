import React from "react";
import PropTypes from "prop-types";
import CenteredImage from "./CenteredImage";
import { Link } from "gatsby";

/**
 * Displays a Card with Link to the article. Has two variants: small and big.
 *
 * variant small = Displays featured images to the left and post info to the right.
 * variant big = Displays featured images on top and post info at the bottom.
 *
 * @param image - Featured Post image URL string
 * @param title - The title of the post
 * @param excerpt - Excerpt of the post. Restricted to 170 Chars
 * @param date - Post Date. Should be already in the right format
 * @param link - Path to the post.
 * @param variant - How the Teaser is displayed. Defaults to 'small'.
 * @param category - Optional category name.
 */
const ArticleTeaser = ({
  imageSrcSet,
  imageSrc,
  title,
  excerpt,
  date,
  link,
  variant,
  category
}) => {
  const parsedExcerpt =
    excerpt.length > 170 ? excerpt.substring(0, 170) + "..." : excerpt;

  const small = (
    <div className="article-teaser article-teaser--small">
      <Link to={link}>
        {(imageSrc || imageSrcSet) && (
          <CenteredImage
            alt={title}
            source={imageSrc}
            sourceSet={imageSrcSet}
            className="article-teaser__image"
            isPortrait
          />
        )}
        <div className="article-teaser__content">
          {category && (
            <div className="article-teaser__category" dangerouslySetInnerHTML={{__html: category}} />
          )}
          <h5
            className="article-teaser__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          { parsedExcerpt && (
            <div
              className="article-teaser__text"
              dangerouslySetInnerHTML={{ __html: parsedExcerpt }}
            />
          )}
          <div className="article-teaser__date">{date}</div>
        </div>
      </Link>
    </div>
  );

  const big = (
    <div className="article-teaser article-teaser--big">
      <Link to={link}>
        <CenteredImage
          alt={title}
          source={imageSrc}
          sourceSet={imageSrcSet}
          isPortrait
          className="article-teaser__image"
        />
        <div className="article-teaser__content">
          {category && (
            <div className="article-teaser__category">{category}</div>
          )}
          <h5
            className="article-teaser__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          { parsedExcerpt && (
            <div className="article-teaser__text">{parsedExcerpt}</div>
          )}
          <div className="article-teaser__date">{date}</div>
        </div>
      </Link>
    </div>
  );

  return variant === "small" ? small : big;
};

ArticleTeaser.defaultProps = {
  variant: "small"
};

ArticleTeaser.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["big", "small"]),
  category: PropTypes.string
};

export default ArticleTeaser;
