import React, { Component } from "react";
import * as JsSearch from "js-search";
import { Row, Col } from "react-bootstrap";
import HugeInput from "./HugeInput";
import ReactPaginate from "react-paginate";
import PostList from "./PostList";
import Mark from "mark.js";

class SearchClient extends Component {
  state = {
    isLoading: true,
    searchResults: [],
    search: null,
    isError: false,
    termFrequency: true,
    removeStopWords: false,
    searchQuery: "",
    selectedStrategy: "",
    selectedSanitizer: "",
    offset: 0,
    pageCount: 0,
    resultsPerPage: 5,
    currentItems: []
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.search === null) {
      const { engine, query } = nextProps;
      return {
        termFrequency: engine.SearchByTerm,
        selectedSanitizer: engine.searchSanitizer,
        selectedStrategy: engine.indexStrategy,
        searchQuery: query
      };
    }
    return null;
  }

  async componentDidMount() {
    await this.rebuildIndex();
    const queryResult =
      this.state.searchQuery === ""
        ? this.props.data
        : this.state.search.search(this.state.searchQuery);
    this.setState(
      {
        searchResults: queryResult,
        pageCount: Math.ceil(queryResult.length / this.state.resultsPerPage),
        currentItems: queryResult.slice(
          this.state.offset,
          this.state.resultsPerPage
        )
      },
      () => this.markSearchWords()
    );
  }

  markSearchWords = () => {
    const titles = document.querySelectorAll(
      ".post-list .article-teaser .article-teaser__title"
    );
    const instance = new Mark(titles);
    instance.mark([this.state.searchQuery], {
      accuracy: "complementary",
      separateWordSearch: false
    });
  };

  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const { selectedStrategy, selectedSanitizer, termFrequency } = this.state;
    const { data } = this.props;

    const dataToSearch = new JsSearch.Search(["node", "wordpress_id"]);

    /**
     * defines an indexing strategy for the data
     * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    if (selectedStrategy === "All") {
      dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    }
    if (selectedStrategy === "Exact match") {
      dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy();
    }
    if (selectedStrategy === "Prefix match") {
      dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    }

    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     */
    selectedSanitizer === "Case Sensitive"
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer());
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("node"))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex());

    // sets the index attribute for the data
    dataToSearch.addIndex(["node", "post_title"]);

    dataToSearch.addDocuments(data); // adds the data to be searched

    this.setState({ search: dataToSearch, isLoading: false });
  };

  /**
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  searchData = e => {
    const { search } = this.state;

    if (e.target.value) {
      const queryResult = search.search(e.target.value);
      this.setState(
        {
          searchQuery: e.target.value,
          searchResults: queryResult,
          pageCount: Math.ceil(queryResult.length / this.state.resultsPerPage),
          currentItems: queryResult.slice(
            this.state.offset,
            this.state.resultsPerPage
          )
        },
        () => this.markSearchWords()
      );
      return;
    }

    this.setState({
      searchQuery: e.target.value,
      searchResults: this.props.data,
      pageCount: Math.ceil(this.props.data.length / this.state.resultsPerPage),
      currentItems: this.props.data.slice(
        this.state.offset,
        this.state.resultsPerPage
      )
    });
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  handlePageClick = data => {
    const selected = data.selected;
    const offset = Math.ceil(selected * this.state.resultsPerPage);

    this.setState(
      {
        offset: offset,
        currentItems: this.state.searchResults.slice(
          offset,
          this.state.resultsPerPage * (selected + 1)
        )
      },
      () => this.markSearchWords()
    );
  };

  render() {
    const { currentItems, searchQuery } = this.state;
    const queryResults = currentItems;
    return (
      <>
        <Row>
          <Col lg={{ span: 10, offset: 1 }}>
            <form onSubmit={this.handleSubmit} className="search-page__form">
              <HugeInput onChange={this.searchData} value={searchQuery} />
            </form>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            <div className="search-page__posts">
              <div className="headline">{ this.props.stringTranslations["Results for"]} “{searchQuery}”</div>
              <div className="number-results">
                { this.props.stringTranslations["Showing"]} {queryResults && queryResults.length}
              </div>

              <PostList posts={queryResults} />

              <ReactPaginate
                previousLabel={ this.props.stringTranslations["Prev"]}
                nextLabel={ this.props.stringTranslations["Next"]}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination-js"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default SearchClient;
