import React from "react";
import Layout from "../../layouts/Layout";
import SearchClient from "../../components/SearchClient";
import { graphql } from 'gatsby';

const Search = ({ pageContext, data, location }) => {
  const { searchOptions } = pageContext;
  const searchQuery =
    new URLSearchParams(location.search).get("keywords") || "";
  const stringTranslations = pageContext.stringTranslations;

  return (
    <Layout
      context={pageContext}
      className="search-page"
      showProgressbar={false}
    >
      <SearchClient
        data={data.allPosts.edges}
        query={searchQuery}
        engine={searchOptions}
        stringTranslations={stringTranslations}
      />
    </Layout>
  );
};

export const query = graphql`
  query SearchQuery($id: Int!, $languageCode: String!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      post_title
      path
      post_content
    }
    allPosts: allWordpressPost(
      filter: { language: { language_code: { eq: $languageCode } } }
    ) {
      edges {
        node {
          post_title
          path
          wordpress_id
          post_excerpt
          acf {
            excerpt
          }
          post_date(locale: $languageCode, formatString: "MMMM D, YYYY")
          featured_media {
            localFile {
              childImageSharp {
                fixed(jpegQuality: 100, height: 200) {
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Search;
