import React from "react";

/**
 * This input is huge.
 */
const HugeInput = ({ id, icon, placeholder, labelText, onChange, value }) => {
  return (
    <div className="huge-input">
      <label htmlFor={id} hidden>
        {labelText}
      </label>
      <input
        id={id}
        value={value}
        aria-label={placeholder}
        onChange={event => onChange(event)}
        placeholder={placeholder}
      />
      <span className={`icon icon-${icon}`} id={id} />
    </div>
  );
};

HugeInput.defaultProps = {
  labelText: "Search",
  id: "Search",
  placeholder: "Search",
  icon: "search"
};

export default HugeInput;
