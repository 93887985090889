import React from "react";
import Col from "react-bootstrap/Col";
import ArticleTeaser from "./ArticleTeaser";
import Row from "react-bootstrap/Row";
import PropTypes from "prop-types";

/**
 * Displays all posts in a column layout.
 *
 * @param posts - allWordpressPost array
 * @param withCategory - boolean value if categories should be displayed in article teaser
 * @param title - Optional section title
 */
const PostList = ({ posts, withCategory, title }) => {
  return (
    <div className="post-list">
      {title && <h5 className="post-list__title">{title}</h5>}
      <Row>
        {posts.map(post => (
          <Col lg={12} key={post.node.path}>
            <ArticleTeaser
              date={post.node.post_date}
              excerpt={post.node?.acf?.excerpt || post.node?.post_excerpt}
              imageSrcSet={
                post.node.featured_media?.localFile?.childImageSharp?.fixed
                  ?.srcSet
              }
              imageSrc={
                post.node.featured_media?.localFile?.childImageSharp?.fixed
                  ?.src
              }
              title={post.node.post_title}
              link={post.node.path}
              category={
                withCategory
                  ? post.node.post_categories &&
                    post.node.post_categories.length > 0 &&
                    post.node.post_categories[0].name
                  : null
              }
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

PostList.defaultProps = {
  withCategory: false,
  title: ""
};

PostList.propTypes = {
  withCategory: PropTypes.bool,
  title: PropTypes.string
};

export default PostList;
