import React from "react";
import PropTypes from "prop-types";

/**
 * Centers a image responsively in the container.
 * @param source - Image source. (string, element)
 * @param alt - Alt Text for image
 * @param isPortrait - If image should be horizontally centered. Defaults to false.
 * @param isRound - If image should be rounded. Defaults to false.
 * @param className - Additional classnames for wrapper
 */
const CenteredImage = ({ source, sourceSet, alt, isPortrait, isRound, className, width, height, caption = '' }) => {
  if (!source && !sourceSet) return null;

  if(caption) {
    caption = <div className="image-caption" dangerouslySetInnerHTML={{__html: caption}} />
  }

  return (
    <div
      className={`image-centered-wrap ${isRound ? "round" : ""} ${className}`}
    >
      <img
        src={source}
        srcSet={sourceSet}
        alt={alt}
        className={isPortrait ? "portrait" : ""}
        width={width}
        height={height}
      />
      {caption}
    </div>
  );
};

CenteredImage.propTypes = {
  source: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  alt: PropTypes.string.isRequired,
  isPortrait: PropTypes.bool,
  isRound: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

CenteredImage.defaultProps = {
  isPortrait: false,
  isRound: false,
  className: "",
  width: undefined,
  height: undefined,
};

export default CenteredImage;
